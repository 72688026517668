/* eslint-disable react/jsx-filename-extension */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import livePerson from '@viking-eng/live-person';
import AnalyticsSite from 'common/analytics/AnalyticsSite';
import CommonWrapper from 'common/layout/CommonWrapper';
import PageLoading from 'common/pageLoading/PageLoading';
import { msalConfig } from 'configs/authConfig';
import { init as mockInit } from 'mocks/browser';
import { StrictMode, Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import 'utils/appInsights';
import { createStore } from './app/store';

const App = lazy(() => import('app/App'));
const CookiesCheck = lazy(() => import('common/cookiesCheck/CookiesCheck'));

const { persistor, store } = createStore();

(async () => {
  await mockInit();
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  if (!livePerson) {
    // eslint-disable-next-line no-console
    console.warn('LivePerson is not available.');
  }

  createRoot(document.getElementById('root')).render(
    <AnalyticsSite>
      <Suspense>
        <StrictMode>
          <CookiesCheck>
            <Provider store={store}>
              <PersistGate loading={<PageLoading />} persistor={persistor}>
                <CommonWrapper>
                  <MsalProvider instance={msalInstance}>
                    <App store={store} />
                  </MsalProvider>
                </CommonWrapper>
              </PersistGate>
            </Provider>
          </CookiesCheck>
        </StrictMode>
      </Suspense>
    </AnalyticsSite>
  );
})();
