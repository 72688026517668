// IMPORTANT: Any changes to ROUTES auth need to also be reflected in App.jsx RequireAuth elements
import { OFFICES, OFFICES_ALL } from './COUNTRIES';

const ROUTES = {
  academy: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 4,
    headerRightOrder: undefined,
    id: 'academy',
    isExternal: true,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'academy',
    pageTitle: undefined,
    title: 'Travel Advisor Academy',
    url: '[API_PATH]/agent/academy',
  },
  agencyRegistration: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 4,
    headerRightOrder: undefined,
    id: 'agency-registration',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: true,
    key: 'agency-id',
    pageTitle: 'Agency ID Overview',
    title: 'Agency Registration',
    url: '/agency-registration',
  },
  account: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: 0,
    id: 'account',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'account',
    pageTitle: 'My Advisor Account',
    title: 'My Advisor Account',
    url: '/account',
  },
  advertising: {
    auth: { offices: OFFICES_ALL },
    footerOrder: 4,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'advertising',
    isExternal: true,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'advertising',
    pageTitle: undefined,
    title: 'Advertising and Commissions Policy',
    url: 'https://docs.vikingcruises.com/pdf/Viking_Advertising_and_Commission_Policy.pdf',
  },
  bestBusinessPartner: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    hidePageTitle: true,
    id: 'business',
    isExternal: false,
    isFooterVisible: true,
    isFullLayout: true,
    isHeaderVisible: true,
    isHeaderLogoHidden: true,
    isModal: false,
    isPrintLinkVisible: false,
    isPublic: true,
    key: 'business',
    pageTitle: undefined,
    title: 'Travel Advisors’ Best Business Partner',
    url: '/best-business-partner',
  },
  bookingCreate: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: 1,
    headerRightOrder: undefined,
    id: 'booking-create',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'booking-create',
    pageTitle: undefined,
    subRoutes: { itinerary: 'itinerary' },
    title: 'Make A Booking',
    url: '/make-a-booking',
  },
  bookingPersonal: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: 1,
    id: 'bookings-personal',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'bookings-personal',
    pageTitle: undefined,
    title: 'My Personal Bookings',
    url: '/bookings-personal',
  },
  bookings: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: 2,
    headerRightOrder: undefined,
    id: 'bookings',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: false,
    isPublic: false,
    key: 'bookings',
    pageTitle: undefined,
    title: 'Manage My Bookings',
    url: '/manage-bookings',
  },
  carrierInfo: {
    auth: { offices: OFFICES_ALL },
    footerOrder: 1,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'carrier-info',
    isExternal: true,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'carrier-info',
    pageTitle: undefined,
    title: 'Carrier Information',
    url: 'https://docs.vikingcruises.com/pdf/5-220816_ShipCarrier.pdf',
  },
  contact: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 9,
    headerRightOrder: undefined,
    id: 'contact',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'contact',
    pageTitle: undefined,
    title: 'Contact Your Sales Representative',
    url: '/resources/contact',
  },
  faq: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    tabs: {
      bookingCruiseVikingAir: 'tap-booking-cruise-air',
      lifeOnBoard: 'life-on-board',
      manageMyBookings: 'manage-my-bookings',
      tripAndDocuments: 'trip-and-document',
      usingTheTravelAdvisorPortal: 'using-the-travel-advisor-portal',
      vikingRewards: 'viking-rewards',
      other: 'other',
    },
    footerOrder: undefined,
    headerLeftOrder: 12,
    headerRightOrder: undefined,
    id: 'agent-faq',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isTitleNotUppercase: true,
    isPublic: false,
    key: 'agent-faq',
    pageTitle: undefined,
    title: 'FAQs',
    url: '/resources/faqs',
  },
  formsGuides: {
    auth: { offices: [OFFICES.AU] },
    footerOrder: undefined,
    headerLeftOrder: 11,
    headerRightOrder: undefined,
    id: 'forms-guides',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'forms-guides',
    pageTitle: undefined,
    title: 'Forms & Guides',
    url: '/forms-guides',
  },
  groups: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 8,
    headerRightOrder: undefined,
    id: 'groups',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'groups',
    pageTitle: undefined,
    title: 'Groups',
    url: '/groups',
  },
  home: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: 0,
    headerRightOrder: undefined,
    id: 'home',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: false,
    isPublic: false,
    key: 'home',
    pageTitle: undefined,
    title: 'Home',
    url: '/home',
    hidePageTitle: true,
  },
  itinerary: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'itinerary',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'itinerary',
    pageTitle: 'Make A Booking',
    title: 'Make A Booking',
    url: '/make-a-booking/itinerary',
  },
  loginFaqs: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'login-faqs',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isVikingNumberVisible: true,
    isTitleNotUppercase: true,
    isPublic: true,
    key: 'login-faqs',
    pageTitle: 'TRAVEL ADVISOR ACCOUNT FAQs',
    title: 'Login FAQs',
    url: '/login-faqs',
  },
  maintenance: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'login-faqs',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isVikingNumberVisible: true,
    isTitleNotUppercase: true,
    isPublic: true,
    key: 'maintenance',
    pageTitle: 'SYSTEM MAINTENANCE',
    title: 'System Maintenance',
    url: '/maintenance',
  },
  marketing: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 3,
    headerRightOrder: undefined,
    id: 'marketing',
    isExternal: true,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'marketing',
    pageTitle: undefined,
    title: 'Marketing Center',
    url: '[API_PATH]/agent/marketingcenter',
  },
  notFound: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'not-found',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: false,
    isPublic: true,
    key: 'not-found',
    pageTitle: undefined,
    title: 'Not Found',
    url: '/not-found',
  },
  platinumClub: {
    auth: { offices: [OFFICES.AU] },
    footerOrder: undefined,
    headerLeftOrder: 10,
    headerRightOrder: undefined,
    id: 'platinum-club',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'platinum-club',
    pageTitle: undefined,
    title: 'Viking Platinum Club',
    url: '/platinum-club',
  },
  privacy: {
    auth: { offices: OFFICES_ALL },
    footerOrder: 3,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'privacy',
    isExternal: false,
    isModal: true,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'privacy',
    pageTitle: undefined,
    isRoutedToNotFoundIfUnauthorized: false,
    title: 'Privacy Policy',
    url: '/privacy',
  },
  resources: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 5,
    headerRightOrder: undefined,
    id: 'resources',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'resources',
    pageTitle: undefined,
    subRoutes: { contact: 'contact', faq: 'faqs', training: 'training', whySellViking: 'why-sell-viking' },
    title: 'Resources',
    url: '/resources',
  },
  rewards: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 7,
    headerRightOrder: undefined,
    id: 'rewards',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'rewards',
    pageTitle: undefined,
    title: 'Viking Rewards',
    url: '/rewards',
  },
  rewardsExternal: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'rewards-external',
    isExternal: true,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: false,
    isPublic: false,
    key: 'rewards-external',
    pageTitle: undefined,
    title: 'Viking Rewards',
    url: '[API_PATH]/agent/rewards',
  },
  security: {
    auth: { offices: OFFICES_ALL },
    footerOrder: 2,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'security',
    isExternal: true,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'security',
    pageTitle: undefined,
    title: 'Vessel Security & Safety Act',
    url: 'https://www.vikingcruises.com/security-guide',
  },
  signedOut: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'signed-out',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: false,
    isPublic: true,
    key: 'signed-out',
    pageTitle: undefined,
    title: 'Signed Out',
    url: '/signed-out',
  },
  siteMap: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'site-map',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'site-map',
    pageTitle: undefined,
    title: 'Site Map',
    url: '/site-map',
  },
  terms: {
    auth: { offices: OFFICES_ALL },
    footerOrder: 0,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'terms',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'terms',
    pageTitle: undefined,
    title: 'Booking Terms & Conditions',
    url: 'https://docs.vikingcruises.com/pdf/2-230303_BookingAndSaleTermsAndConditions.pdf',
  },
  training: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'training',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'training-webinars',
    pageTitle: undefined,
    title: 'Training Materials, Webinars & TA Rates',
    url: '/resources/training',
  },
  version: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'version',
    isExternal: false,
    isModal: false,
    isFooterVisible: false,
    isHeaderVisible: false,
    isLayoutHeaderInvisible: true,
    isPrintLinkVisible: false,
    isPublic: true,
    key: 'version',
    pageTitle: undefined,
    title: 'Version',
    url: '/version',
  },
  website: {
    auth: { offices: [OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: 6,
    headerRightOrder: undefined,
    id: 'website',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'website',
    pageTitle: undefined,
    subRoutes: { confirmed: 'websiteConfirmed' },
    title: 'My Co-Branded Website',
    url: '/website',
  },
  websiteConfirmed: {
    auth: { offices: [OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'website-confirmed',
    isExternal: false,
    isModal: false,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPrintLinkVisible: true,
    isPublic: false,
    key: 'website-confirmed',
    pageTitle: undefined,
    title: 'My Co-Branded Website - Thank You',
    url: '/website/confirmed',
  },
  welcome: {
    auth: { offices: OFFICES_ALL },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    hidePageTitle: true,
    id: 'welcome',
    isExternal: false,
    isFooterVisible: true,
    isFullLayout: true,
    isHeaderVisible: true,
    isModal: false,
    isPrintLinkVisible: false,
    isPublic: true,
    key: 'welcome',
    pageTitle: undefined,
    title: 'Welcome',
    url: '/welcome',
  },
  whySellViking: {
    auth: { offices: [OFFICES.AU, OFFICES.CA, OFFICES.US] },
    footerOrder: undefined,
    headerLeftOrder: undefined,
    headerRightOrder: undefined,
    id: 'why-sell-viking',
    isExternal: false,
    isModal: false,
    isPrintLinkVisible: true,
    isFooterVisible: true,
    isHeaderVisible: true,
    isPublic: false,
    key: 'why-sell-viking',
    pageTitle: undefined,
    title: 'Why Sell Viking',
    url: '/resources/why-sell-viking',
  },
};

export default ROUTES;
